import Picture1 from "../assets/images/mainBanners/interes-en-tu-primer-prestamo.png";
import Picture2 from "../assets/images/mainBanners/new-product-600-soles.png";
import Picture3 from "../assets/images/mainBanners/monthly-giveaways.png";
import Picture4 from "../assets/images/mainBanners/mobile-app.png";

import { socialMediaLinks } from "./socialMediaLinks.js";

export const banners = [
  {
    src: Picture1,
    alt: "Persona sonriente con dinero en efectivo y un celular",
    link: socialMediaLinks.mobileApp,
  },
  {
    src: Picture2,
    alt: "New product - 600 soles",
    link: socialMediaLinks.mobileApp,
  },
  {
    src: Picture3,
    alt: "Monthly giveaways",
    link: socialMediaLinks.mobileApp,
  },
  {
    src: Picture4,
    alt: "Mobile App",
    link: socialMediaLinks.mobileApp,
  },
];
