import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";

import "./styles.scss";

import { banners } from "../../consts/mainBanners";

const Carousel = () => {
  return (
    <div className="main-carousel-block">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Mousewheel]}
        grabCursor={true}
        loop={true}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <span className="main-carousel-block__slide">slide - {index}</span>
            <a target="_blank" href={banner.link} rel="noreferrer">
              <img src={banner.src} alt={banner.alt} />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
